import React, { Component } from 'react';
import visa from '../assets/img/payment/visa.png'
import mastercard from '../assets/img/payment/mastercard.png'
import amex from '../assets/img/payment/amex.png'
import apple_pay from '../assets/img/payment/apple_pay.png'
import google_pay from '../assets/img/payment/google_pay.png'
import discover from '../assets/img/payment/discover.png'
import { isMobile } from 'react-device-detect';


class PaymentPill extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name } = this.props;
    let icon;
    if(name === 'visa') icon = visa;
    if(name === 'mastercard') icon = mastercard;
    if(name === 'apple_pay') icon = apple_pay;
    if(name === 'google_pay') icon = google_pay;
    if(name === 'amex') icon = amex;
    if(name === 'discover') icon = discover;
    return(
      <div style={styles.wrapper}>
        <img src={icon} style={styles.icon} />
      </div>
    );
  }
}

const styles = {
  icon: {
    height: isMobile ? 25 : 30,
    marginRight: isMobile ? 8 : 15,
    marginTop: 10
  }
};

export default PaymentPill;