import { combineReducers } from 'redux';
import * as types from './types';

const defaultConfig = {
  loading: true,
  colors: {}
}

export function siteConfigReducer(state = defaultConfig, action) {
  switch (action.type) {
    case types.UPDATE_SITE_CONFIG:
      return({
        ...state,
        ...action.payload,
        loading: false
      })
    default: return state;
  }
}



const allReducers = combineReducers({
  siteConfig: siteConfigReducer,
})

export default (state, action) => {
  return allReducers(state, action);
}