export default {
  border: '#ddd',

  text: '#191919',
  medText: '#394C5F',
  lightText: '#ababab',

  lightBG: '#f7f7f7',
  darkBG: '#ededed',

  green: '#30D158',
  red: '#FF453A',
  yellow: '#FFD60A',
  lightBlue: '#94C8FF',
}