import React, { Component } from 'react';
import ImageSection from '../../sections/ImageSection/ImageSection';

import site_editor from '../../assets/img/site_editor.png';
import manage_order from '../../assets/img/manage_order.png';
import mobile_order from '../../assets/img/mobile_order.png';
import mobile_order_blur from '../../assets/img/mobile_order_blur.png';
import invoice from '../../assets/img/invoice.png';

import ArrowLink from '../../components/ArrowLink';
import PaymentPill from '../../components/PaymentPill';

class DetailerImageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <>
      <ImageSection
        image={site_editor}
        title='Easily build your own website'
        descText='Customize one of our optimized templates in as little as 5 minutes.'
        descExtra={
          <div style={{marginTop: 7}}>
            <ArrowLink href='https://detailer-demo-1.detailpages.com/' text='View Example Website Created With DetailPages' />
          </div>
        }
      />
      <ImageSection
        image={invoice}
        reversed
        title='Email & Text Invoices, and accept credit cards'
        descText={'All you need is a bank account # to accept the deposits. Customers pay directly on their phone or computer.'}
        descExtra={
          <div style={styles.pillsRow}>
            <PaymentPill name='visa' />
            <PaymentPill name='mastercard' />
            <PaymentPill name='amex' />
            <PaymentPill name='discover' />
            <PaymentPill name='apple_pay' />
            <PaymentPill name='google_pay' />
            
          </div>
        }
      />

      <ImageSection
        image={mobile_order_blur}
        title='Manage & Schedule Your Orders'
        descText={'Create your own orders, or have customers submit them on your site. Manage order progess through quoting, scheduling, and payment.'}
        isPhone={true}
      />
{/* 
<ImageSection
        reverse
        image={manage_order}
        title='Simple Mobile & Web Interfaces'
        descText={'A powerful, yet simple app that is easy to use.'}
        descExtra={
          <div style={styles.pillsRow}>

            
          </div>
        }
      /> */}
      </>
    );
  }
}

const styles = {
  arrowRight: {
    marginLeft: 10,
    height: 15
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'red',
  },
  pillsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
};

export default DetailerImageSection;