import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Button from '../../components/Button';
const animateTime = 9; // tenths of a sec

class BottomCTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animateVal: 0,
      hideBox: false
    };
  }

  componentDidMount(){
    this.startAnimate();
  }

  componentWillUnmount(){
    if(this.animateInterval) clearInterval(this.animateInterval);
  }

  startAnimate = () => {
    this.animateInterval = setInterval(() => {
      this.setState({hideBox: false}, () => {
        setTimeout(() => {
          this.setState({
            animateVal: 1,
          }, () => {
            setTimeout(() => {
              this.setState({hideBox: true, animateVal: 0})
            }, (animateTime*100)+30);
          })
        }, 100);
      })
    }, 3000);
  }

  render() {
    console.log(this.state)
    const { animateVal, hideBox } = this.state;

    const styles = {
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
      },
      box: {
        maxWidth: isMobile ? '90%' : 1000,
        margin: 20,
        padding: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#1D2B3A',
        boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
        borderRadius: 4,
        flex: 1,
        position: 'relative',
        zIndex: 2,
        marginBottom: 30
      },
      message: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 0,
        textAlign: 'center',
        width: '100%'
      },
      left: {
        zIndex: 2

      },
      right: {
        zIndex: 2

      },
      nocc: {
        color: '#fff',
        fontSize: 16,
        // fontWeight: 600,
        marginBottom: 10,
        opacity: .6,
        textAlign: 'center',
        width: '100%',
        marginTop: -4
      }
    
    };
     const animateSize = 20;
    let animateBoxStyle = {
      transition: `.${animateTime}s`,
      position: 'absolute',
      top: -(animateSize*animateVal),
      left: -(animateSize*animateVal),
      bottom: -(animateSize*animateVal),
      right: -(animateSize*animateVal),
      backgroundColor: '#1D2B3A',
      opacity: animateVal ? 0 : .5,
      zIndex: 1,
      borderRadius: 4

    }

    return(
      <div style={styles.wrapper}>
          <div style={styles.box}>
            {hideBox ? null : <div style={animateBoxStyle} /> }
            <div style={styles.left}>
              <div style={styles.message}>
                Try out Detail Pages for Free <br />
              </div>
                <div style={styles.nocc}>No credit card required</div>
              <Button text="START FREE TRIAL" big arrow isSignup />
            </div>
            <div style={styles.right}>
              
            </div>
          </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(BottomCTA);