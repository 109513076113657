import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class ImageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { reversed, image, title, descText, descExtra, isPhone} = this.props;

    if(isMobile) reversed = false;


const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 30
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: 1000,
    width: '100%',
    padding: 20
  },
  content1: {

  },
  content2: {
    marginTop: isMobile ? 15 : 0
  },
  left: {
    flex: isMobile ? undefined : .5,
    width: isMobile ? '100%' : undefined,
    paddingRight: isMobile ? 0 : 40
  },
  right: {
    position: 'relative',
    flex: isMobile ? undefined : .5,
    width: isMobile ? '100%' : undefined 
  },
  title: {
    fontSize: 24,
    fontWeight: 600
  },
  desc: {

  },
  image: {
    objectFit: 'contain',
    width: '100%',
    boxShadow: isPhone ? undefined  :'0 0 9px 3px rgba(0,0,0,.05)',
    borderRadius: isPhone ? undefined : 4,
    border: isPhone ? undefined : '1px solid '+this.props.siteConfig.colors.border,
    maxHeight: isPhone ? 500 : undefined
  }
};

const content1 = (
  <div style={styles.content1}>
    <div style={styles.title}>{title}</div>
    <div style={styles.desc}>
      {descText}
      {descExtra}
      </div>
  </div>
)


const content2 = (
  <div style={styles.content2}>
    <img src={image} style={styles.image} />
  </div>
)

    return(
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <div style={styles.left}>
            {reversed ? content2 : content1}
          </div>
          <div style={styles.right}>
            {reversed ? content1 : content2}
          </div>
        </div>

      </div>
    );
  }
}


const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(ImageSection);