import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import Button from "../../components/Button";
import CheckItem from "./CheckItem";
import detailerVideo2 from "../../assets/video/detail_bigger.mp4";
import colors from "../../assets/colors";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const content = (
      <div style={styles.left}>
        <CheckItem text={"Website Builder"} />
        <CheckItem text={"Order Management & Scheduling"} />
        <CheckItem text={"Invoice & Accept Credit Cards"} />
        <div style={styles.message}>
          And More! We handle everything digital for your car detailing business. Get up and running in as little as 15 minutes!
        </div>
        <Button big text="Start Free 14 Day Trial" arrow isSignup />
      </div>
    );

    const video = (
      <div style={styles.right}>
        <video style={isMobile ? styles.videoStyleMobile : styles.video} autoPlay loop muted playsInline>
          <source src={detailerVideo2} type="video/mp4" />
        </video>
      </div>
    );
    return (
      <div style={styles.wrapper}>
        <div style={styles.content}>
          {isMobile ? video : content}
          {isMobile ? content : video}
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 1,

    // backgroundColor: 'red',
  },
  content: {
    // maxWidth: 1000,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    zIndex: 1,
  },
  left: {
    maxWidth: 520,
    // flex: .5,
    width: isMobile ? "100%" : "50%",
    // paddingRight: isMobile ? 0 : 20,
    padding: 20,
    paddingRight: isMobile ? 20 : 40,
    marginBottom: isMobile ? 80  : 100,
    marginTop: isMobile ? 0 : 80,
  },
  right: {
    // maxWidth: 600,
    // flex: .5,
    width: isMobile ? "100%" : "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,

    // minWidth
  },
  message: {
    marginTop: 15,
    marginBottom: 15,
    color: colors.medText,
    fontSize: 16,
  },
  more: {
    // fontSize: 1,
    color: "grey",
    paddingLeft: 40,
  },
  tryForFreeText: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 20,
  },
  video: {
    height: "100%",
    position: "absolute",
    width: "50vw",
    right: 0,
    objectFit: "cover",
    filter: "saturate(1.1) brightness(1.3)",
    zIndex: 1,

    // borderRadius: 3
  },
  videoStyleMobile: {
    width: "100%",
  },
};

export default Hero;
