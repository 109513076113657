import React, { Component } from 'react';
import { updateSiteConfig } from './redux/actions';
import DetailPages from './sites/DetailPages';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if(window.location.href.includes('detailpages.com')){
      return <DetailPages />
    }

    return <DetailPages />;
  }
}

const styles = {

};

export default App;