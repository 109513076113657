import React, { Component } from 'react';
import Hero from '../../sections/Hero';
import Navbar from '../../sections/Navbar';
import logo from '../../assets/img/dp1.png';
import dp2 from '../../assets/img/dp2.png';
import { updateSiteConfig } from '../../redux/actions';
import colors from '../../assets/colors';
import ObjectionsBar from '../../sections/ObjectionsBar';
import BottomCTA from '../../sections/BottomCTA';
import Footer from '../../sections/Footer';
import DetailerImageSection from './DetailerImageSection';
import Pricing from '../../sections/Pricing/Pricing';

const config = {
  colors: {
    ...colors,
    main: '#3561AC',


  }
}

class DetailPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPricing: false
    };
  }

  componentDidMount(){
    updateSiteConfig(config);

  }

  goToPricing = () => {
    this.setState({showPricing: true}, () => {
      setTimeout(() => {
        document.getElementById('pricing_section').scrollIntoView()
      }, 50);
    })
  }

  render() {
    return( 
      <>
      <Navbar logo={dp2} goToPricing={this.goToPricing}/>
      <Hero />
      <ObjectionsBar />
      <div style={{height: 50}} />
      <DetailerImageSection />
      {this.state.showPricing ? <Pricing /> : null}
      <BottomCTA />
      <Footer logo={dp2} goToPricing={this.goToPricing} />
      </>
    );
  }
}

const styles = {

};

export default DetailPages;