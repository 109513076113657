import React, { Component } from 'react';
import checkIcon from '../../assets/img/check.png';
import greenCheckIcon from '../../assets/img/check_green.png';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

class CheckItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { text, small } = this.props;
    const styles = {
      wrapper: {
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      check: {
        height: 25,
        marginRight: 10,
        opacity: .9,
        opacity: small ? 0  : 1
      },
      text: {
        fontSize: isMobile ?  24 : 28,
        fontWeight:  600,
        color: small ? this.props.siteConfig.colors.lightText : undefined
      }
    };
    return(
      <div style={styles.wrapper}>
        <img src={greenCheckIcon} style={styles.check} />
        <div style={styles.text}>
            {text}
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(CheckItem);