import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { icon, text } = this.props;
    return(
      <div style={styles.wrapper}>
        <img style={styles.icon} src={icon} />
          <div style={styles.text}>{text}</div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: isMobile ? '45%' : 180,
    marginTop: isMobile ? 30 : 20

  },
  text: {
    color: '#fff',
    fontWeight: 600,
    textAlign: 'center',
    opacity: .9
  },
  icon: {
    height: 40,
    filter: 'brightness(0) invert(1)',
    opacity: .9,
    marginBottom: 6
  }
};

export default Item;