import * as types from './types';
import store from './store';

export function updateSiteConfig(x){
  store.dispatch({
    type: types.UPDATE_SITE_CONFIG,
    payload: x
  })
}




