import React, { Component } from "react";
import Item from "./Item";
import snapIcon from "../../assets/img/easy.png";
import customerSupportIcon from "../../assets/img/customer_support.png";
import flagIcon from "../../assets/img/flag.png";
import wave3 from "../../assets/img/wave3.png";

class ObjectionsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
{/*         
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={styles.svg}>
            <path
              fill="#303030"
              fill-opacity="1"
              d="M0,256L80,240C160,224,320,192,480,197.3C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg> */}

          <img src={wave3} style={styles.wavePng} />
     

        <div style={styles.content}>
          <Item icon={snapIcon} text="No software knowledge required - easy to use" />
          <Item icon={customerSupportIcon} text="Excellent customer support" />
          <Item icon={flagIcon} text="100% American owned and operated" />
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    backgroundColor: "#303030",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 4,
    // marginTop: 50
  },
  content: {
    padding: 10,
    paddingTop: 0,
    maxWidth: 1000,
    backgroundColor: "#303030",
    zIndex: 4,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: 20,
    marginBottom: 20,
  },

  wavePng: {
    width: '100%',
    position: 'absolute',
    bottom: '99%',
    maxHeight: 50

  },

  svg: {
    stroke: "none",
    fill: "#303030",
    zIndex: 3,
    // maxHeight: 200,
    objectFit: 'contain',
    width: "100vw",
    position: "absolute",
    bottom: '90%',
    left: 0,
    right: 0,
  },
};

export default ObjectionsBar;
