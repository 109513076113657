import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickPricing = () => {

  }


  onClickTrial = () => {
    window.location = 'https://app.detailpages.com/signup'
  }

  onClickLogin = () => {
    window.location = 'https://app.detailpages.com/login'
  }

  render() {
    return(
        <div style={styles.wrapper}>
            <div style={styles.content}>
              <div style={styles.left}>
                <img src={this.props.logo} style={styles.logo} />
                <div style={{height: 8}} />
                <div style={styles.textItem}>
                  A website builder and order management system for car detailers.
                </div>
                <div style={{height: 15}} />


              </div>
              <div style={styles.middle}>
                <div style={styles.linkContainer}>
                  {isMobile ? <div style={styles.link} onClick={this.props.goToPricing} className='footerLink1'>Pricing</div> : null}
                  <div style={styles.link} onClick={this.onClickTrial} className='footerLink1'>Free Trial</div>
                  <div style={styles.link} onClick={this.onClickLogin} className='footerLink1'>Log In</div>
                </div>
              </div>
              <div style={styles.right}>
                <div style={styles.linkContainer}>
                  <div style={styles.questions}>Questions? Contact Us</div>
                    {/* <div style={styles.textItem}>+1 330 212 0070</div> */}
                    <div style={styles.textItem}>info@bluecollarpages.com</div>
                    <div style={{height: 4}} />
                    <div style={styles.textItem}>
                      2515 Jay Ave.<br/>
                      Cleveland, OH 44113<br/>
                      USA
                    </div>
                </div>
              </div>
            </div>
            <div style={styles.bottomBar}>
            <div style={styles.copy}>
            © {new Date().getFullYear()} Blue Collar Pages
            </div>
              
            </div>
        </div>
    );
  }
}

const styles = {
  wrapper: {
    backgroundColor: '#F5F5F5',
    borderTopWidth: 1,
    borderTopColor: '#E9E9E9',
    borderTopStyle: 'solid',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  content: {
    maxWidth: 1000,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: 20,
    marginBottom: 40
  },
  logo: {
    height: 40
  },
  textItem: {
    fontSize :14,
    color: '#858585',
    fontWeight: 300,
    lineHeight: 1.4
  },
  address: {

  },
  left: {
    flex: isMobile ? undefined : .333,
    width: isMobile ? '100%' : undefined
  },
  middle: {
    flex: isMobile ? undefined : .333,
    width: isMobile ? '100%' : undefined,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: isMobile ? 'flex-start' : 'center',
    paddingBottom: 10

  },
  right: {
    flex: isMobile ? undefined : .333,
    width: isMobile ? '100%' : undefined,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: isMobile ? 'flex-start' : 'center',

  },
  questions: {
    color: '#858585',
    fontSize :14,

    marginBottom: 8
  },
  bottomBar: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  copy: {
    opacity: .3,
    fontSize: 12,
    textAlign: 'center'
  },
  link: {
    fontSize :14,
    color: '#858585',
    fontWeight: 300,
    cursor: 'pointer',
    marginBottom: 7
  }
};

const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(Footer);