import React, { Component } from 'react';
import { connect } from 'react-redux';
import colors from '../assets/colors';
import arrowIcon from '../assets/img/arrow_right_blue.png';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  onMouseEnter = () => {
    this.setState({hover: true})
  }

  onMouseLeave = () => {
    this.setState({hover: false})
  }

  onClick = () => {
    if(this.props.isSignup){
      window.location = 'https://app.detailpages.com/signup';
    }else{
      this.props.onClick()
    }
  }

  render() {
    const { text, buttonStyle={}, big, arrow, white} = this.props;
    const { hover } = this.state;

    const styles = {
      wrapper: {
        borderRadius: 4,
        height: big ? 60 : 40,
        paddingLeft: big ? 25 : 15,
        paddingRight: big ? 25 : 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: white ? '#fff' : this.props.siteConfig.colors.main,
        color: '#fff',
        fontWeight: 600,
        cursor: 'pointer',
        transition: '.1s',
      },
      text: {
        color: white ? this.props.siteConfig.colors.main : '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: big ? 20 : 16,
        fontWeight: 700
      },
      arrow: {
        filter: white ? undefined : 'brightness(0) invert(1)',
        height: 20,
        marginLeft: hover ? 14 : 10,
        transition: '.2s'
      }
    };
    return(
      <div style={{...styles.wrapper, ...buttonStyle}} onClick={this.onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <div style={styles.text}>
          {text}
          {arrow ? <img src={arrowIcon} style={styles.arrow} /> : null}
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(Button);