import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import ccIcon from '../../assets/img/payment/cc.png';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const styles = {
      wrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 25
      },
      content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: isMobile ? '100%' : 1000
      },
      left: {
        paddingRight: 40
      },
      planTitle: {
        fontWeight: 'bold',
        fontSize: 24,
        textAlign: 'center'
      },
      desc: {
        color: this.props.siteConfig.colors.medText,
        textAlign: 'center',
        marginBottom: 30

      },
      priceCard: {
        width: isMobile ? '86%' : 270,
        borderRadius: 4,
        boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
        border: `1px solid ${this.props.siteConfig.colors.main}`,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    
      },
      price: {
        fontSize: 36,
        position: 'relative'
        // fontWeight: 
      },
      dollarSign: {
        position: 'absolute',
        top: 0,
        left: -12,
        color: this.props.siteConfig.colors.lightText,
        fontSize: 16 
        
      },
      month: {
        position: 'absolute',
        bottom: 6,
        right: -54,
        color: this.props.siteConfig.colors.lightText,
        fontSize: 16
      },
      pricingTitle: {
        fontSize: 24,
        fontWeight: 600
      },
      planDesc: {
        maxWidth: isMobile ? '100%' : 300
      },
      cc: {
        height: 22,
        marginRight: 7,
        opacity: .7
      },
      ccDesc: {
        color: this.props.siteConfig.colors.lightText,
        fontSize: 14
      },
      ccRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }
    };

    return(
      <div style={styles.wrapper}>
        <div style={styles.content} id='pricing_section'>

            <div style={styles.priceCard}>
              <div style={styles.planTitle}>PRICING</div>
              <div style={styles.desc}>One pricing plan, which includes unlimited access to everything.</div>

              <div style={styles.price}>
                <span style={styles.dollarSign}>$</span>
                29
                <span style={styles.month}>/month</span>
              </div>
              <div style={styles.ccRow}>
                  <img src={ccIcon} style={styles.cc} />
                  <div style={styles.ccDesc}>2.9% fee from processor</div>
                </div>
              <Button text='Get Started' buttonStyle={{width: '80%', marginTop: 20}} />
            </div>
         
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(Pricing);