import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import { Dropdown, Menu } from 'antd';
import { isMobile } from 'react-device-detect';
import { MenuOutlined } from '@ant-design/icons'

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSignupClick = () => {
    window.location = 'https://app.detailpages.com/signup'
  }

  onLoginClick = () => {
    window.location = 'https://app.detailpages.com/login'

  }

  render() {
    const { logo } = this.props;
    const { colors } = this.props.siteConfig;

    const styles = {
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        boxShadow: '0 0 9px 3px rgba(0,0,0,.05)',
        paddingBottom: 5,
      },
      content: {
        maxWidth: 1200,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      left: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        paddingLeft: 20,
        paddingTop: 20,
    
      },
      right: {
        padding: 10,
        paddingRight: 20,
        paddingTop: 20,
    
        // flex :1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      logo: {
        height: isMobile ? 35 : 50
      },
      login: {
        fontWeight: 600,
        color: colors.medText,
        cursor: 'pointer'
      },
      logoText: {
        fontSize: 32,
        fontWeight: 700,
        color: this.props.siteConfig.colors.main,
        marginLeft: 7

      },
      item: {
        fontSize: 18,
        marginBottom: 6,
        marginTop: 6
      }
    };

    const menu = (
      <Menu style={{width: 200}}>
        <Menu.Item>
          <div style={styles.item} onClick={this.props.goToPricing}>Pricing</div>
        </Menu.Item>
        <Menu.Item>
          <div style={styles.item} onClick={this.onLoginClick}>Member Login</div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => this.onSignupClick} style={styles.item}>Get Started For Free</div>
        </Menu.Item>
      </Menu>
    )
    return(
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <div style={styles.left}>
            <img src={logo} style={styles.logo} />
            {/* <div style={styles.logoText}>Detail Pages</div> */}
          </div>
          <div style={styles.right}>
            {isMobile ? 
            <div style={styles.menuWrapper}>
              <Dropdown trigger='click' overlay={menu}>
                <MenuOutlined style={{fontSize :20, margin: 7}} />
              </Dropdown>
            </div>
            :
            <>
              <div style={styles.login} onClick={this.props.goToPricing}>Pricing</div>
              <div style={{width: 20}}/>
              <div style={styles.login} onClick={this.onLoginClick}>
                Member Login
              </div>
              <Button text='Try for FREE' buttonStyle={{marginLeft: 20}} isSignup/>
            </>
            }
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(Navbar);