import React, { Component } from 'react';
import arrow_right from '../assets/img/arrow_right.png';
import arrow_right_blue from '../assets/img/arrow_right_blue.png';
import { connect } from 'react-redux';

class ArrowLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { text, href } = this.props;
    const { hover } = this.state;

    const styles = {
      link: {
        color: 'blue',
        cursor: 'pointer'
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: this.props.siteConfig.colors.main,
        fontWeight: 'bold'

        // justifyContent: 'center',
      },
      arrowRight: {
        transition: '.2s',
        marginLeft: hover ? 15 : 10,
        height: 20,
      }
    };

    return(
      <div
      onClick={() => window.open(href, '_blank')}
      style={styles.link}
      onMouseEnter={() => this.setState({hover: true})}
      onMouseLeave={() => this.setState({hover: false})}
      >
        <div style={styles.row}>
        {text}
        <img src={arrow_right_blue} style={styles.arrowRight} />
          
        </div>
    </div>
    );
  }
}



const mapStateToProps = state => ({
  siteConfig: state.siteConfig
});
const mapActionsToProps = {
};
export default connect(mapStateToProps, mapActionsToProps)(ArrowLink);